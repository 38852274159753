import React, { useContext } from 'react';
import { IAccountFullData } from '../../_shared/interfaces/account-full-data';
import { msalApp, msalConfig, b2cPolicies } from '../../lib/msal/msal';
import { AccountInfo, AuthenticationResult, RedirectRequest, PopupRequest, InteractionRequiredAuthError } from '@azure/msal-browser';
import axios from 'axios';
import { IDevice } from '../../_shared/interfaces/device';
import { IAccountData } from '../../_shared/interfaces/account-data';
import { IAddress } from '../../_shared/interfaces/address';
import { IContractData } from '../../_shared/interfaces/contract-data';
import { ICostEstimateData } from '../../_shared/interfaces/cost-estimate';
import { trackEvent } from '../../lib/track/track';
import { isStoryblokEditor } from '../../lib/storyblok/storyblok';
import ModalContext from '../modal/modal.context';
import { oktaAuth } from '../../lib/okta/okta';
import { AccessToken, IDToken, CustomUserClaims, UserClaims, Token, Tokens } from '@okta/okta-auth-js';

const oktaScopes = ['offline_access', 'openid', 'profile', 'email', 'Legacy'];

export interface IAccountContext {
    account: AccountInfo | UserClaims<CustomUserClaims> | null;
    error: string | null;
    registrationLoading: boolean;

    signUp: (language: string, theme: 'hs' | 'dr', callback?: () => any) => void;
    signIn: (language: string, theme: 'hs' | 'dr') => Promise<void>;
    signOut: () => void;
    updateToken: () => Promise<void>;
    forgotPassword: (language: string, theme: 'hs' | 'dr') => void;
    changePassword: (language: string, theme: 'hs' | 'dr') => void;
    checkForMissingData: (accountData: IAccountData | null, addresses: IAddress[] | null) => string[] | undefined;

    accountData: IAccountData | null;
    accountDataRequest: (forceUpdate?: boolean) => Promise<IAccountData>;
    updateAccountData: (accountData: IAccountData) => Promise<void>;
    updateAll: (fullData: IAccountFullData) => Promise<void>;

    addresses: IAddress[] | null;
    addressesRequest: (forceUpdate?: boolean) => Promise<IAddress[]>;
    addAddress: (address: IAddress) => Promise<void>;
    updateAddress: (address: IAddress) => Promise<void>;
    deleteAddress: (addressId: string) => Promise<void>;

    devices: IDevice[] | null;
    devicesRequest: (force?: boolean) => Promise<void>;
    addDevice: (device: IDevice) => Promise<void>;
    updateDevice: (device: IDevice) => Promise<void>;
    deleteDevice: (deviceId: string) => Promise<void>;

    contractDataList: IContractData[] | null;
    contractDataListRequest: () => Promise<void>;
    contractDataFileDownloadRequest: (filename: string) => Promise<void>;
    resetContractDataList: () => Promise<void>;

    costEstimates: ICostEstimateData[] | null;
    costEstimatesRequest: () => Promise<void>;
    resetCostEstimates: () => Promise<void>;

    getAccountId: () => string | undefined;
}

export const defaultAccountContext: IAccountContext = {
    account: null,
    error: null,
    registrationLoading: false,

    signUp: () => {},
    signIn: async () => {},
    signOut: () => {},
    updateToken: async () => {},
    forgotPassword: () => {},
    changePassword: () => {},
    checkForMissingData: () => [],

    accountData: null,
    accountDataRequest: async (): Promise<any> => {},
    updateAccountData: async () => {},
    updateAll: async () => {},

    addresses: null,
    addressesRequest: async (): Promise<any> => {},
    updateAddress: async () => {},
    addAddress: async () => {},
    deleteAddress: async () => {},

    devices: null,
    devicesRequest: async () => {},
    addDevice: async () => {},
    updateDevice: async () => {},
    deleteDevice: async () => {},

    contractDataList: null,
    contractDataListRequest: async () => {},
    contractDataFileDownloadRequest: async () => {},
    resetContractDataList: async () => {},

    costEstimates: null,
    costEstimatesRequest: async () => {},
    resetCostEstimates: async () => {},

    getAccountId: () => undefined,
};

export const AccountContext = React.createContext<IAccountContext>({ ...defaultAccountContext });
export type AccountContextProviderState = {
    account: AccountInfo | UserClaims<CustomUserClaims> | null;
    error: string | null;
    accountData: IAccountData | null;
    addresses: IAddress[] | null;
    devices: IDevice[] | null;
    contractDataList: IContractData[] | null;
    costEstimates: ICostEstimateData[] | null;
    registrationLoading: boolean;
};
class AccountContextProvider extends React.Component<{}, AccountContextProviderState> {
    constructor(props: Readonly<IAccountContext>) {
        super(props);
        this.state = {
            account: null,
            error: null,
            accountData: null,
            addresses: null,
            devices: null,
            contractDataList: null,
            costEstimates: null,
            registrationLoading: false,
        };
    }

    static contextType = ModalContext;

    private accountAPI = `${process.env.GATSBY_HS_API_BASE}/${process.env.GATSBY_HS_API_ACCOUNT}`;

    private currentLanguage = '';
    private currentTheme = '';

    private usePopup = false;

    private useOkta = false;

    private setUsePopup(): void {
        const isStoryblok = isStoryblokEditor();
        if (this.useOkta) {
            this.usePopup = !!process.env.GATSBY_OKTA_LOGIN_POPUP || isStoryblok;
        } else {
            this.usePopup = !!process.env.GATSBY_MSAL_LOGIN_POPUP || isStoryblok;
        }
    }

    private setUseOkta(): void {
        this.useOkta = !!process.env.GATSBY_HS_USE_OKTA;
    }

    public componentDidMount(): void {
        this.setUseOkta();
        if (!this.useOkta && msalApp) {
            msalApp
                .handleRedirectPromise()
                .then((response) => this.redirectSuccess(response))
                .catch((error) => this.redirectError(error));
        } else if (this.useOkta) {
            oktaAuth.handleRedirect().then(async () => {
                const accessToken = (await oktaAuth.tokenManager.get('accessToken')) as AccessToken;
                const idToken = (await oktaAuth.tokenManager.get('idToken')) as IDToken;

                if (accessToken && idToken) {
                    this.redirectSuccessOkta(accessToken, idToken);
                }
            });
        }
        this.setUsePopup();
        this.selectAccount();
    }

    public checkForMissingData(accountData: IAccountData | null, addresses: IAddress[] | null): string[] | undefined {
        if (accountData) {
            const missingFields: string[] = [];

            if (!accountData.firstName || accountData.firstName.length === 0) {
                missingFields.push('firstName');
            }

            if (!accountData.lastName || accountData.lastName.length === 0) {
                missingFields.push('lastName');
            }

            if (!accountData.eMail || accountData.eMail.length === 0) {
                missingFields.push('email');
            }

            if (!accountData.business || accountData.business.length === 0) {
                missingFields.push('business');
            }

            if (!accountData.profession || accountData.profession.length === 0) {
                missingFields.push('profession');
            }

            if (
                !addresses ||
                addresses.length === 0 ||
                !addresses[0].company ||
                addresses[0].company.length === 0 ||
                addresses[0].company.length > 40 ||
                (addresses[0].supplement && addresses[0].supplement.length > 40)
            ) {
                missingFields.push('address');
            }

            if (missingFields.length > 0) {
                return missingFields;
            }
        }
    }

    /*
     * Redirect handler for successfull redirect callbacks (lib/msal/authRedirect.js)
     */
    private redirectSuccess(response: any) {
        if (!response) {
            return;
        }

        this.selectAccount();

        if (response.accessToken) {
            axios.defaults.headers.common.Authorization = 'Bearer ' + response.accessToken;
        }

        if (response.account) {
            const acr = response.idTokenClaims.acr.toUpperCase();
            if (acr === b2cPolicies.names.signUpSignIn.toUpperCase() || acr === b2cPolicies.names.signUp.toUpperCase()) {
                this.register(`${process.env.GATSBY_COUNTRY}`);
            }

            if (acr === b2cPolicies.names.signUp.toUpperCase() && this.signUpCallback) {
                this.signUpCallback(response);
            }

            this.setState({ account: response.account });
        }
    }

    /*
     * Redirect handler for successfull okta redirect callbacks
     */
    private redirectSuccessOkta(accessToken: AccessToken, idToken: IDToken) {
        this.register(`${process.env.GATSBY_COUNTRY}`);
        this.selectAccountOkta();

        axios.defaults.headers.common.Authorization = 'Bearer ' + accessToken.accessToken;
    }

    /*
     * Redirect handler for error redirect callbacks (lib/msal/authRedirect.js)
     */
    private redirectError(error: any) {
        if (!error) {
            return;
        }

        if (error) {
            this.selectAccount();
            const errorMessage = error.errorMessage ? error.errorMessage : 'Unable to acquire access token.';
            this.setState({
                error: errorMessage,
            });
        }
    }

    private setAccount(accountIn: AccountInfo | null) {
        if (!this.useOkta && msalApp) {
            msalApp.setActiveAccount(accountIn);
        }

        this.setState({
            account: accountIn,
        });
    }

    private setAccountOkta(accountIn: UserClaims<CustomUserClaims> | null) {
        this.setState({
            account: accountIn,
        });
    }

    private selectAccount() {
        if (!this.useOkta && msalApp) {
            /**
             * See here for more info on account retrieval:
             * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
             */

            const currentAccounts = msalApp.getAllAccounts();

            if (currentAccounts.length < 1) {
                this.setAccount(null);
            } else if (currentAccounts.length > 1) {
                /**
                 * Due to the way MSAL caches account objects, the auth response from initiating a user-flow
                 * is cached as a new account, which results in more than one account in the cache. Here we make
                 * sure we are selecting the account with localAccountId that contains the sign-up/sign-in user-flow,
                 * as this is the default flow the user initially signed-in with.
                 */
                const accounts = currentAccounts.filter(
                    (a: AccountInfo) =>
                        a.homeAccountId.toUpperCase().includes(b2cPolicies.names.signUpSignIn.toUpperCase()) &&
                        (a.idTokenClaims?.iss ?? '').toUpperCase().includes(b2cPolicies.authorityDomain.toUpperCase()) &&
                        (a.idTokenClaims?.aud ?? '') === msalConfig.auth.clientId,
                );

                if (accounts.length > 1) {
                    // localAccountId identifies the entity for which the token asserts information.
                    if (accounts.every((a) => a.localAccountId === accounts[0].localAccountId)) {
                        // All accounts belong to the same user
                        this.setAccount(accounts[0]);
                    } else {
                        // Multiple users detected. Logout all to be safe.
                        this.signOut();
                        this.setAccount(null);
                    }
                } else if (accounts.length === 1) {
                    this.setAccount(accounts[0]);
                }
            } else if (currentAccounts.length === 1) {
                this.setAccount(currentAccounts[0]);
            }
        } else if (oktaAuth) {
            this.selectAccountOkta();
        }
    }

    private async selectAccountOkta() {
        const accessToken = (await oktaAuth.tokenManager.get('accessToken')) as AccessToken | undefined;

        oktaAuth.token
            .getUserInfo(accessToken)
            .then((userclaims) => {
                this.setAccountOkta(userclaims);
            })
            .catch((error) => {
                if (error.errorCode === 'invalid_token') {
                    oktaAuth.tokenManager.clear();
                }
            });
    }

    private getRedirectTarget(): any {
        const redirectTarget = typeof window !== 'undefined' ? window.self.location.href : msalConfig.auth.redirectUri;
        const redirectOrigin = typeof window !== 'undefined' ? window.self.location.origin : msalConfig.auth.redirectUri;
        return { redirectCallOrigin: redirectOrigin, redirectCallTarget: redirectTarget };
    }

    private getRequestInfo(authority: string, language: string, theme: string): any {
        this.currentLanguage = language;
        this.currentTheme = theme;

        const scopes = process.env.GATSBY_HS_MSAL_SCOPE?.split(',') ?? [];
        scopes.push('openid');

        const { redirectCallOrigin, redirectCallTarget } = this.getRedirectTarget();
        return {
            scopes,
            authority,
            extraQueryParameters: {
                country: `${process.env.GATSBY_COUNTRY}`,
                theme,
                language,
            },
            redirectStartPage: redirectCallTarget,
            redirectUri: redirectCallOrigin,
        };
    }

    public signUp = async (
        language: string,
        theme: 'hs' | 'dr',
        callback?: (response: void | AuthenticationResult) => any,
    ): Promise<void> => {
        trackEvent('user.register', {});

        if (!this.useOkta && msalApp) {
            const requestInfo = this.getRequestInfo(b2cPolicies.authorities.signUp.authority, language, theme);
            if (this.usePopup) {
                await this.signUpPopup(requestInfo, callback);
            } else {
                await this.signUpRedirect(requestInfo, callback);
            }
        } else if (this.useOkta) {
            if (this.usePopup) {
                this.signUpOktaPopup();
            } else {
                this.signUpOkta();
            }
        }
    };

    private async signUpPopup(requestInfo: any, callback?: (response: void | AuthenticationResult) => any): Promise<void> {
        if (!msalApp) {
            return;
        }

        const onSignUpResponse = await msalApp.loginPopup(requestInfo).catch((error: any) => {
            this.setState({
                error: error.message,
            });
        });

        if (onSignUpResponse) {
            msalApp.setActiveAccount(onSignUpResponse.account);
            await this.register(requestInfo.extraQueryParameters?.language ?? 'DE');
            this.setState({
                account: onSignUpResponse.account,
                error: null,
            });
            if (callback) {
                callback(onSignUpResponse);
            }
        }
    }

    /// ----------------------------
    /// Section: ACCOUNT INFO (Signup, Signin, Signout)
    /// ----------------------------
    signUpCallback: any;

    private async signUpRedirect(requestInfo: any, callback?: (response: void | AuthenticationResult) => any): Promise<void> {
        if (!msalApp) {
            return;
        }
        this.signUpCallback = callback;
        await msalApp.loginRedirect(requestInfo);
    }

    private signUpOkta(): void {
        oktaAuth.token.getWithRedirect({
            responseType: ['token', 'id_token', 'refresh_token'],
            scopes: oktaScopes,
        });
    }

    private signUpOktaPopup(): void {
        oktaAuth.token
            .getWithPopup({
                responseType: ['token', 'id_token', 'refresh_token'],
                scopes: oktaScopes,
            })
            .then((response) => {
                oktaAuth.tokenManager.setTokens(response.tokens);
            })
            .catch(() => {});
    }

    public signIn = async (language: string, theme: 'hs' | 'dr'): Promise<void> => {
        trackEvent('user.login', {});

        if (!this.useOkta && msalApp) {
            const requestInfo = this.getRequestInfo(b2cPolicies.authorities.signUpSignIn.authority, language, theme);

            if (this.usePopup) {
                await this.signInPopup(requestInfo);
            } else {
                await this.signInRedirect(requestInfo);
            }
        } else if (this.useOkta) {
            if (this.usePopup) {
                this.signInOktaPopup();
            } else {
                this.signInOkta();
            }
        }
    };

    private signInPopup = async (requestInfo: PopupRequest): Promise<void> => {
        if (!msalApp) {
            return;
        }

        await msalApp
            .loginPopup(requestInfo)
            .then(async (requestResult) => {
                if (requestResult) {
                    if (!this.useOkta && msalApp) {
                        msalApp.setActiveAccount(requestResult.account);
                    }
                    await this.register(requestInfo.extraQueryParameters?.language ?? 'DE');
                    this.setState({
                        account: requestResult.account,
                        error: null,
                    });
                } else {
                    this.selectAccount();
                }
            })
            .catch((error: any) => {
                this.setState({
                    error: error.message,
                });
            });
    };

    private signInRedirect = async (requestInfo: RedirectRequest): Promise<void> => {
        if (!msalApp) {
            return;
        }
        await msalApp.loginRedirect(requestInfo);
    };

    private signInOkta(): void {
        oktaAuth.token.getWithRedirect({
            responseType: ['token', 'id_token', 'refresh_token'],
            scopes: oktaScopes,
        });
    }

    private signInOktaPopup(): void {
        oktaAuth.token
            .getWithPopup({
                responseType: ['token', 'id_token', 'refresh_token'],
                scopes: oktaScopes,
            })
            .then((response) => {
                oktaAuth.tokenManager.setTokens(response.tokens);
            })
            .catch(() => {});
    }

    public signOut = (): void => {
        if (!this.useOkta && msalApp) {
            const logoutRequest = {
                postLogoutRedirectUri: msalConfig.auth.redirectUri,
                mainWindowRedirectUri: msalConfig.auth.redirectUri,
            };

            if (this.usePopup) {
                msalApp.logoutPopup(logoutRequest);
            } else {
                msalApp.logoutRedirect(logoutRequest);
            }
        } else if (this.useOkta) {
            oktaAuth.signOut().then(() => {
                oktaAuth.tokenManager.clear();
            });
        }
    };

    private resetError = () => {
        this.setState({ error: null });
    };

    public forgotPassword = async (language: string, theme: 'hs' | 'dr'): Promise<void> => {
        if (!this.useOkta && msalApp) {
            this.resetError();
            const requestInfo = this.getRequestInfo(b2cPolicies.authorities.passwordReset.authority, language, theme);

            if (this.usePopup) {
                await this.forgotPasswordPopup(requestInfo);
            } else {
                await this.forgotPasswordRedirect(requestInfo);
            }
        } else if (this.useOkta) {
            if (this.usePopup) {
                this.forgotPasswordOktaPopup();
            } else {
                this.forgotPasswordOkta();
            }
        }
    };

    private forgotPasswordPopup = async (requestInfo: PopupRequest) => {
        if (!msalApp) {
            return;
        }
        const resetPasswordResult = await msalApp.loginPopup(requestInfo).catch((error: any) => {
            this.setState({
                error: error.message,
            });
        });
    };

    private forgotPasswordRedirect = async (requestInfo: RedirectRequest) => {
        await msalApp?.loginRedirect(requestInfo);
    };

    private forgotPasswordOkta(): void {
        oktaAuth.token.getWithRedirect({
            responseType: ['token', 'id_token', 'refresh_token'],
            scopes: oktaScopes,
        });
    }

    private forgotPasswordOktaPopup(): void {
        oktaAuth.token
            .getWithPopup({
                responseType: ['token', 'id_token', 'refresh_token'],
                scopes: oktaScopes,
            })
            .then((response) => {
                oktaAuth.tokenManager.setTokens(response.tokens);
            })
            .catch(() => {});
    }

    public changePassword = async (language: string, theme: 'hs' | 'dr'): Promise<void> => {
        if (!this.useOkta && msalApp) {
            this.resetError();
            const requestInfo = this.getRequestInfo(b2cPolicies.authorities.passwordChange.authority, language, theme);

            if (this.usePopup) {
                await this.changePasswordPopup(requestInfo);
            } else {
                await this.changePasswordRedirect(requestInfo);
            }
        } else if (this.useOkta) {
            if (this.usePopup) {
                this.changePasswordOktaPopup();
            } else {
                this.changePasswordOkta();
            }
        }
    };

    private changePasswordPopup = async (requestInfo: PopupRequest) => {
        if (!msalApp) {
            return;
        }
        const cangePasswordResult = await msalApp.loginPopup(requestInfo).catch((error: any) => {
            this.setState({
                error: error.message,
            });
        });
    };

    private changePasswordRedirect = async (requestInfo: RedirectRequest) => {
        await msalApp?.loginRedirect(requestInfo);
    };

    private changePasswordOkta(): void {
        if (!window) {
            return;
        }

        window.open(process.env.GATSBY_HS_OKTA_CENTER, '_blank');
    }

    private changePasswordOktaPopup(): void {
        if (!window) {
            return;
        }

        window.open(process.env.GATSBY_HS_OKTA_CENTER, '_blank');
    }

    /// ----------------------------
    /// Section: ACCOUNT DATA
    /// ----------------------------

    public accountDataRequest = (forceUpdate?: boolean): Promise<IAccountData> => {
        return new Promise((resolve, reject) => {
            if (this.state.accountData && !forceUpdate) {
                resolve(this.state.accountData);
            } else {
                if (!this.state.registrationLoading) {
                    this.getAccountData()
                        .then((accountData) => {
                            this.setState({ accountData }, () => {
                                resolve(accountData);
                            });
                        })
                        .catch((e) => {
                            reject(e);
                        });
                } else {
                    reject('Registration is still loading');
                }
            }
        });
    };

    public updateAccountData = (accountData: IAccountData): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.getAccountId()) {
                const updatedData: IAccountData = { ...this.state.accountData, ...accountData };
                this.refreshToken().then(() => {
                    const url = this.accountAPI + '/' + this.getAccountId();
                    axios
                        .patch(url, updatedData)
                        .then(() => {
                            this.setState({ accountData: { ...updatedData } }, () => resolve());
                        })
                        .catch((error) => reject(error));
                });
            } else {
                reject('localAccountId does not exists');
            }
        });
    };

    public updateAll = (fullData: IAccountFullData): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.getAccountId()) {
                this.refreshToken().then(() => {
                    const url = `${process.env.GATSBY_HS_API_BASE}/${process.env.GATSBY_HS_API_FULL}`.replace(
                        '%%USER-ID%%',
                        this.getAccountId() ?? '',
                    );
                    axios
                        .post(url, fullData)
                        .then(() => {
                            resolve();
                        })
                        .catch((error) => reject(error));
                });
            } else {
                reject('localAccountId does not exists');
            }
        });
    };

    /// ----------------------------
    /// Section: ADDRESS DATA
    /// ----------------------------

    public addressesRequest = (forceUpdate?: boolean): Promise<IAddress[]> => {
        return new Promise((resolve, reject) => {
            if (this.state.addresses && !forceUpdate) {
                resolve(this.state.addresses);
            } else {
                this.getAddresses()
                    .then((addresses) => {
                        this.setState({ addresses }, () => {
                            resolve(addresses);
                        });
                    })
                    .catch((e) => {
                        reject(e);
                    });
            }
        });
    };

    public updateAddress = (address: IAddress): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (!address.id) {
                reject('Address has no ID');
            } else if (this.getAccountId()) {
                this.refreshToken().then(() => {
                    const url = `${process.env.GATSBY_HS_API_BASE}/${process.env.GATSBY_HS_API_ADDRESS_UPDATE}`
                        .replace('%%ADDRESS-ID%%', address.id ?? '')
                        .replace('%%USER-ID%%', this.getAccountId() ?? '');
                    axios
                        .put(url, address)
                        .then(() => {
                            const updatedAddresses = (this.state.addresses ?? []).map((a) => {
                                if (a.id === address.id) {
                                    return address;
                                } else {
                                    return a;
                                }
                            });
                            this.setState({ addresses: updatedAddresses }, () => resolve());
                        })
                        .catch((error) => reject(error));
                });
            } else {
                reject('localAccountId does not exists');
            }
        });
    };

    public addAddress = async (address: IAddress): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.getAccountId()) {
                this.refreshToken().then(() => {
                    const url = `${process.env.GATSBY_HS_API_BASE}/${process.env.GATSBY_HS_API_ADDRESS_ADD}`.replace(
                        '%%USER-ID%%',
                        this.getAccountId() ?? '',
                    );
                    axios
                        .post(url, address)
                        .then((response) => {
                            const updatedAddresses = this.state.addresses ? this.state.addresses : [];
                            updatedAddresses.push({ id: response.data, ...address });
                            this.setState({ addresses: updatedAddresses }, () => resolve());
                        })
                        .catch((error) => reject(error));
                });
            } else {
                reject('localAccountId does not exists');
            }
        });
    };

    public deleteAddress = async (addressId: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.getAccountId()) {
                this.refreshToken().then(() => {
                    const url = `${process.env.GATSBY_HS_API_BASE}/${process.env.GATSBY_HS_API_ADDRESS_UPDATE}`
                        .replace('%%USER-ID%%', this.getAccountId() ?? '')
                        .replace('%%ADDRESS-ID%%', addressId);

                    axios
                        .delete(url)
                        .then(() => {
                            this.setState(
                                {
                                    addresses: (this.state.addresses ? this.state.addresses : ([] as IAddress[])).filter(
                                        (a) => a.id !== addressId,
                                    ),
                                },
                                () => resolve(),
                            );
                        })
                        .catch((error) => reject(error));
                });
            } else {
                reject('localAccountId does not exists');
            }
        });
    };

    /// ----------------------------
    /// Section: DEVICE DATA
    /// ----------------------------
    public updateDevice = (device: IDevice): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (!device.id) {
                reject('Device has no ID');
            } else if (this.getAccountId()) {
                this.refreshToken().then(() => {
                    const url = `${process.env.GATSBY_HS_API_BASE}/${process.env.GATSBY_HS_API_DEVICE_UPDATE}`
                        .replace('%%DEVICE-ID%%', device.id ?? '')
                        .replace('%%USER-ID%%', this.getAccountId() ?? '');
                    axios
                        .put(url, device)
                        .then(() => {
                            const updatedDevices = (this.state.devices ?? []).map((a) => {
                                if (a.id === device.id) {
                                    return device;
                                } else {
                                    return a;
                                }
                            });
                            this.setState({ devices: updatedDevices }, () => resolve());
                        })
                        .catch((error) => reject(error));
                });
            } else {
                reject('localAccountId does not exists');
            }
        });
    };

    public addDevice = async (device: IDevice): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.getAccountId()) {
                this.refreshToken().then(() => {
                    const url = `${process.env.GATSBY_HS_API_BASE}/${process.env.GATSBY_HS_API_DEVICE_ADD}`.replace(
                        '%%USER-ID%%',
                        this.getAccountId() ?? '',
                    );
                    axios
                        .post(url, device)
                        .then((response) => {
                            const updatedDevices = this.state.devices ? this.state.devices : [];
                            updatedDevices.push({ ...response.data });
                            this.setState({ devices: updatedDevices }, () => resolve());
                        })
                        .catch((error) => reject(error));
                });
            } else {
                reject('localAccountId does not exists');
            }
        });
    };

    public deleteDevice = async (deviceId: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.getAccountId()) {
                this.refreshToken().then(() => {
                    const url = `${process.env.GATSBY_HS_API_BASE}/${process.env.GATSBY_HS_API_DEVICE_UPDATE}`
                        .replace('%%USER-ID%%', this.getAccountId() ?? '')
                        .replace('%%DEVICE-ID%%', deviceId);

                    axios
                        .delete(url)
                        .then(() => {
                            this.setState(
                                {
                                    devices: (this.state.devices ? this.state.devices : ([] as IDevice[])).filter((a) => a.id !== deviceId),
                                },
                                () => resolve(),
                            );
                        })
                        .catch((error) => reject(error));
                });
            } else {
                reject('localAccountId does not exists');
            }
        });
    };

    public devicesRequest = (force?: boolean): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.state.devices && !force) {
                resolve();
            } else {
                this.getDevices()
                    .then((devices) => {
                        this.setState({ devices }, () => {
                            resolve();
                        });
                    })
                    .catch((e) => {
                        reject(e);
                    });
            }
        });
    };

    /// ----------------------------
    /// Section: CONTRACT DATA
    /// ----------------------------
    public contractDataListRequest = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.state.contractDataList) {
                resolve();
            } else {
                this.getContractDataList()
                    .then((contractDataList) => {
                        this.setState({ contractDataList }, () => {
                            resolve();
                        });
                    })
                    .catch((e) => {
                        reject(e);
                    });
            }
        });
    };

    public contractDataFileDownloadRequest = (filename: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (!filename) {
                resolve();
            } else {
                this.downloadContractDataFile(filename)
                    .then(() => {
                        resolve();
                    })
                    .catch((e) => {
                        reject(e);
                    });
            }
        });
    };

    public resetContractDataList = (): Promise<void> => {
        return new Promise((resolve) => {
            this.setState({ contractDataList: null }, () => {
                resolve();
            });
        });
    };

    /// ----------------------------
    /// Section: COST ESTIMATES
    /// ----------------------------

    public costEstimatesRequest = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.state.costEstimates) {
                resolve();
            } else {
                this.getCostEstimates()
                    .then((costEstimates) => {
                        this.setState({ costEstimates }, () => {
                            resolve();
                        });
                    })
                    .catch((e) => {
                        reject(e);
                    });
            }
        });
    };

    public resetCostEstimates = (): Promise<void> => {
        return new Promise((resolve) => {
            this.setState({ costEstimates: null }, () => {
                resolve();
            });
        });
    };

    public render() {
        const { children } = this.props;
        return (
            <AccountContext.Provider
                value={{
                    ...this.state,
                    signIn: this.signIn,
                    signOut: this.signOut,
                    forgotPassword: this.forgotPassword,
                    changePassword: this.changePassword,
                    checkForMissingData: this.checkForMissingData,
                    signUp: this.signUp,
                    updateAccountData: this.updateAccountData,
                    updateAll: this.updateAll,
                    updateAddress: this.updateAddress,
                    addAddress: this.addAddress,
                    deleteAddress: this.deleteAddress,
                    accountDataRequest: this.accountDataRequest,
                    addressesRequest: this.addressesRequest,
                    updateDevice: this.updateDevice,
                    addDevice: this.addDevice,
                    deleteDevice: this.deleteDevice,
                    devicesRequest: this.devicesRequest,
                    updateToken: this.updateToken,
                    contractDataListRequest: this.contractDataListRequest,
                    resetContractDataList: this.resetContractDataList,
                    contractDataFileDownloadRequest: this.contractDataFileDownloadRequest,
                    costEstimatesRequest: this.costEstimatesRequest,
                    resetCostEstimates: this.resetCostEstimates,
                    getAccountId: this.getAccountId,
                }}
            >
                {children}
            </AccountContext.Provider>
        );
    }

    public getAccountId = (): string | undefined => {
        if (this.state.account) {
            if ('b2cid' in this.state.account) {
                return this.state.account.b2cid as string;
            } else if ('oktaid' in this.state.account) {
                return this.state.account.oktaid as string;
            }
        }
        return this.state.account?.localAccountId as string | undefined;
    };

    private getAccountData = (): Promise<IAccountData> => {
        return new Promise((resolve, reject) => {
            if (this.getAccountId()) {
                this.refreshToken()
                    .then(() => {
                        const url = this.accountAPI + '/' + this.getAccountId();
                        axios
                            .get(url, {})
                            .then((response) => {
                                resolve(response.data);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                    .catch((e) => {
                        reject(e);
                    });
            } else {
                reject('localAccountId does not exists');
            }
        });
    };

    private getAddresses = (): Promise<IAddress[]> => {
        return new Promise((resolve, reject) => {
            const accountId = this.getAccountId();
            if (accountId) {
                const url = `${process.env.GATSBY_HS_API_BASE}/${process.env.GATSBY_HS_API_ADDRESSES}`.replace('%%USER-ID%%', accountId);

                this.refreshToken()
                    .then(() => {
                        axios
                            .get(url, {})
                            .then((response) => {
                                resolve(response.data);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                    .catch((e) => {
                        reject(e);
                    });
            } else {
                reject('localAccountId does not exists');
            }
        });
    };

    private getDevices = (): Promise<IDevice[]> => {
        return new Promise((resolve, reject) => {
            const accountId = this.getAccountId();
            if (accountId) {
                const url = `${process.env.GATSBY_HS_API_BASE}/${process.env.GATSBY_HS_API_DEVICES}`.replace('%%USER-ID%%', accountId);
                this.refreshToken()
                    .then(() => {
                        axios
                            .get(url, {})
                            .then((response) => {
                                resolve(response.data);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                    .catch((e) => {
                        reject(e);
                    });
            } else {
                reject('localAccountId does not exists');
            }
        });
    };

    private getContractDataList = (): Promise<IContractData[]> => {
        return new Promise((resolve, reject) => {
            const accountId = this.getAccountId();
            if (accountId) {
                const url = `${process.env.GATSBY_HS_API_BASE}/${process.env.GATSBY_HS_API_CONTRACT_DATA}`.replace(
                    '%%USER-ID%%',
                    accountId,
                );
                this.refreshToken()
                    .then(() => {
                        axios
                            .get(url, {})
                            .then((response) => {
                                resolve(response.data);
                            })
                            .catch((error) => {
                                resolve(new Array<IContractData>());
                                reject(error);
                            });
                    })
                    .catch((e) => {
                        reject(e);
                    });
            } else {
                reject('localAccountId does not exists');
            }
        });
    };

    private downloadContractDataFile = (filename: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            const accountId = this.getAccountId();
            if (accountId) {
                const url = `${process.env.GATSBY_HS_API_BASE}/${process.env.GATSBY_HS_API_CONTRACT_DATA_FILE_DOWNLOAD}`
                    .replace('%%USER-ID%%', accountId)
                    .replace('%%FILENAME%%', encodeURIComponent(filename));
                this.refreshToken().then(() => {
                    axios
                        .get(url, {
                            responseType: 'arraybuffer',
                        })
                        .then((response) => {
                            const link = document.createElement('a');
                            const blob = new Blob([response.data], { type: 'application/pdf' });
                            link.href = window.URL.createObjectURL(blob);
                            link.setAttribute('download', filename);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            resolve();
                        })
                        .catch((e) => {
                            reject(e);
                        });
                });
            } else {
                reject('localAccountId does not exists');
            }
        });
    };

    private getCostEstimates = (): Promise<ICostEstimateData[]> => {
        return new Promise((resolve, reject) => {
            const accountId = this.getAccountId();
            if (accountId) {
                const url = `${process.env.GATSBY_HS_API_BASE}/${process.env.GATSBY_HS_API_COST_ESTIMATES}`.replace(
                    '%%USER-ID%%',
                    accountId,
                );

                this.refreshToken()
                    .then(() => {
                        axios
                            .get(url, {})
                            .then((response) => {
                                resolve(response.data);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                    .catch((e) => {
                        reject(e);
                    });
            } else {
                reject('localAccountId does not exists');
            }
        });
    };

    private acquireToken = (forceRefresh: boolean = false): Promise<AuthenticationResult | Token | undefined> => {
        return new Promise((resolve, reject) => {
            if (!this.useOkta && msalApp) {
                const requestInfo = this.getRequestInfo(
                    b2cPolicies.authorities.signUpSignIn.authority,
                    this.currentLanguage,
                    this.currentTheme,
                );

                requestInfo.account = this.state.account;

                msalApp
                    .acquireTokenSilent(requestInfo)
                    .then((response: AuthenticationResult) => {
                        if (!response.accessToken || response.accessToken === '') {
                            throw new InteractionRequiredAuthError();
                        }
                        resolve(response);
                    })
                    .catch((error) => {
                        // reject('session timeout');
                        if (error instanceof InteractionRequiredAuthError) {
                            // hier lande ich wenn die Session abgelaufen ist: es wird das Login-Popup geöffnet
                            // @ts-ignore
                            resolve(this.aquireTokenSwitch(requestInfo));
                            reject('Non-interactive error:' + error.errorCode);
                        }
                    });
            } else if (this.useOkta) {
                oktaAuth.tokenManager.get('accessToken').then((token) => {
                    if (token && !forceRefresh) {
                        resolve(token);
                    } else {
                        resolve(this.aquireTokenSwitch({ responseType: ['token', 'id_token'] }));
                    }
                });
            } else {
                reject();
            }
        });
    };

    private aquireTokenSwitch = (requestInfo: any): Promise<AuthenticationResult | Token | undefined> => {
        return new Promise((resolve, reject) => {
            if (!this.useOkta && msalApp) {
                if (this.usePopup) {
                    return msalApp.acquireTokenPopup(requestInfo);
                } else {
                    msalApp.acquireTokenRedirect(requestInfo);
                }
            } else {
                oktaAuth.token.renewTokens().then((tokens: Tokens) => {
                    if (tokens.accessToken) {
                        oktaAuth.tokenManager.setTokens(tokens);
                        resolve(tokens.accessToken);
                    } else {
                        reject('Could not refresh token!');
                    }
                });
            }
        });
    };

    public updateToken = (): Promise<void> => {
        this.resetError();
        return new Promise((resolve) => {
            if (!this.state.account) {
                resolve();
            } else {
                this.refreshToken(true).then(() => resolve());
            }
        });
    };

    private refreshToken = (forceRefresh: boolean = false): Promise<void> => {
        return new Promise((resolve, reject) => {
            this.acquireToken(forceRefresh)
                .then((token) => {
                    if (token) {
                        if (!this.useOkta && msalApp && 'accessToken' in token) {
                            axios.defaults.headers.common.Authorization = 'Bearer ' + token.accessToken;
                        } else if (this.useOkta) {
                            axios.defaults.headers.common.Authorization = 'Bearer ' + (token as AccessToken).accessToken;
                        }
                        resolve();
                    } else {
                        reject('Could not refresh token!');
                    }
                })
                .catch((e) => {
                    // hier lande ich, wenn das Popup zum erneuten anmelden einfach zugemacht wird
                    // Ich logge den User dann einfach aus, was sicherlich kein Ideal-Zustand ist
                    this.signOut();
                    reject(e);
                });
        });
    };

    private register = (language: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            const url = `${process.env.GATSBY_HS_API_BASE}/${process.env.GATSBY_HS_API_REGISTER}?language=${language}`;

            this.setRegistrationLoading(true);

            this.refreshToken().then(() => {
                oktaAuth.getUser().then((user: CustomUserClaims) => {
                    const registerData = {
                        retailer: localStorage.getItem('retailer'),
                        firstName: user.firstname,
                        lastName: user.lastname,
                    };
                    axios
                        .post(url, registerData)
                        .then(() => {
                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        })
                        .finally(() => {
                            this.setRegistrationLoading(false);
                        });
                });
            });
        });
    };

    private setRegistrationLoading = (loading: boolean) => {
        this.setState({ registrationLoading: loading });
    };
}

export const useUserAccount = () => useContext(AccountContext);

export default AccountContext;
export { AccountContextProvider };
